import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/users', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/users/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/users', userData)
          .then(response => resolve(response))
          .catch(error => reject(error.response.data))
      })
    },
    removeLink(ctx, linkData) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/users/${linkData.userId}/chatlinks/${linkData.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    banLink(ctx, linkData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/users/${linkData.userId}/chatlinks/${linkData.id}/ban`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    revokeLink(ctx, linkData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/users/${linkData.userId}/chatlinks/${linkData.id}/revoke`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createLink(ctx, linkData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/users/${linkData.userId}/chatlinks`, {chat_id: linkData.id})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
